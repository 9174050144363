import axios from 'axios';

const getEmployeeInfo = (employeeId, isAuditor, cancelToken = null, docAndOrders = true) => {
  return new Promise((resolve, reject) => {
    axios
    .get(`/v1/employees/${employeeId}?${docAndOrders ? 'with=docInfo,orders' : ''}${isAuditor ? '&auditor' : ''}`, {cancelToken})
    .then(response => {
      return resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error('Failed to load employee file', err);
      reject(err);
    });
  });
};

const getEmployeeDocTypes = (employeeId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .get(`/v1/employees/${employeeId}/doctypes?includeSDU=true`, {cancelToken})
    .then(response => {
      return resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error('Failed to get employee doc types', err);
      reject(err);
    });
  });
};

const copyEmployeeDocuments = (fromEmployeeId, toEmployeeId, docsMap, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .post(`/v1/employees/${toEmployeeId}/mergeEmployees?fromEmployeeId=${fromEmployeeId}`, {docsMap}, {cancelToken})
    .then(response => {
      return resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error('Failed to copy employee docs', err);
      reject(err);
    });
  });
};

const updateEmployee = (employeeId, updates, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .patch(`/v1/employees/${employeeId}`, updates, {cancelToken})
    .then(response => {
      return resolve();
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      reject(err);
    });
  });
};

/**
 * Replacement for delete employee. 
 * Queries for a deactivated client location set up as an employee archive
 * Creates the location if one does not exist
 * Transfers employee to the employee archive
 * If an applicant exists for the EMID, cancel the applicant
 * 
 * @param {Object} params
 * @param {Number} params.employeeId - The legacyEmployeeId (EMID) of the employee to archive
 * @param {Number} params.topParentClientId - The legacyClientId of the top parent client
 * @param {Number} params.employmentStatusId - The employment status ID used when transferring the employee (EMSID)
 * @param {Number} params.jobTitleId - The job title ID used when transferring the employee (JTID)
 * @param {CancelToken} params.cancelToken - The cancel token used to cancel the request
 * @returns {Promise} - A promise that resolves when the employee has been archived
 */
const archiveEmployee = async ({
  employeeId, 
  topParentClientId,
  employmentStatusId,
  jobTitleId, 
  cancelToken = null
}) => {
  try {
    await axios.patch(`/v1/employees/${employeeId}/archiveEmployee/${topParentClientId}`, {
      employmentStatusId,
      jobTitleId,
      cancelToken
    });
    return;
  } catch (err) {
    if (axios.isCancel(err)) {
      throw new Error(err.message);
    }
    throw err;
  }
};

/**
 * Replaces employee EMIDs with the new employee EMID
 * Deletes the old employee
 * @param {string} fromEmployee - The EMID of the employee to replace
 * @param {string} toEmployee - The EMID of the employee to replace with
 * @param {number} toEmployeeEmploymentStatusId - The employment status ID of the new employee
 * @param {CancelToken} cancelToken - The cancel token to cancel the request
 * @returns {Promise} - The promise that resolves when the request is complete
 */
const replaceEmployee = async ({
  fromEmployeeId, 
  toEmployeeId, 
  toEmployeeEmploymentStatusId,
  cancelToken = null
}) => {
  try {
    const response = await axios.patch(`/v1/employees/replace/${fromEmployeeId}/${toEmployeeId}`, {
      employmentStatusId: toEmployeeEmploymentStatusId
    }, { cancelToken });
    return response.data;
  } catch (err) {
    console.error('Failed to replace employee', err);
    if (axios.isCancel(err)) {
      throw err.message;
    }
    throw err;
  }
};

const getEmployeeDrugAlcoholInfo = (employeeId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .get(`/v1/employees/${employeeId}/drugAlcoholInfo`, {cancelToken})
    .then(response => {
      return resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error('Failed to load employee drug and alcohol data', err);
      reject(err);
    });
  });
};

const getCMEDocuments = (employeeId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .get(`/v1/employees/${employeeId}/cmeDocuments`, {cancelToken})
    .then(response => {
      return resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error('Failed to load employee documents', err);
      reject(err);
    });
  });
};


const getMatchingDocuments = (employeeId, docTypeId, docDate, docExpirationDate, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .get(`/v1/employees/${employeeId}/documents?docTypeId=${docTypeId}&docDate=${docDate}${docExpirationDate ? `&docExpirationDate=${docExpirationDate}` : ''}`, {cancelToken})
    .then(response => {
      return resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error('Failed to load employee documents', err);
      reject(err);
    });
  });
};

const getDCHConsents = (employeeId, license, state, dotNumber, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .get(`/v1/employees/${employeeId}/dchConsents?license=${license}&state=${state}&dotNumber=${dotNumber}`, {cancelToken})
    .then(response => {
      return resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error('Failed to find dch consents documents', err);
      reject(err);
    });
  });
};

const getEmployeeRuleMatches = (employeeId, ruleId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .get(`/v1/employees/${employeeId}/ruleMatches/${ruleId}`, {cancelToken})
    .then(response => {
      return resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error('Failed to find rule matches', err);
      reject(err);
    });
  });
};

const findMVR = async (employeeId, docImageId, cancelToken = null) => {
  return (await axios.get(`/v1/employees/${employeeId}/findMVR?docImageId=${docImageId}`, {cancelToken})).data;
}

export default {
  getEmployeeInfo,
  getEmployeeDocTypes,
  copyEmployeeDocuments,
  updateEmployee,
  archiveEmployee,
  getEmployeeDrugAlcoholInfo,
  getCMEDocuments,
  getMatchingDocuments,
  getDCHConsents,
  getEmployeeRuleMatches,
  findMVR,
  replaceEmployee
};